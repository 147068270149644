import React, { Component } from 'react';
import PropTypes from 'prop-types';
import connect from './connector';

const places = typeof window !== `undefined` ? require("places.js") : null

class HomePlaces extends Component {

static propTypes = {
    refine: PropTypes.func.isRequired,
    defaultRefinement: PropTypes.object.isRequired,
};

createRef = c => (this.element = c);

componentDidMount() {
  const { refine, defaultRefinement } = this.props;

  const options = {
    container: this.element,
    appId: process.env.ALGOLIA_PLACES_APP_ID,
    apiKey: process.env.ALGOLOA_PLACES_API_KEY,
    // Algolia Places options
  };

  const reconfigurableOptions = {
    language: 'en', // Receives results in German
    countries: 'us', // Search in the United States of America
    type: 'city', // Search only for cities names
    aroundLatLngViaIP: true // disable the extra search/boost around the source IP
  };
  const autocomplete = places(options).configure(reconfigurableOptions);

  autocomplete.on('change', event => {
    refine(event.suggestion.latlng);
    if (typeof window !== undefined) {
      let query = event.suggestion.latlng;
      window.location.replace(`/search/?aroundLatLng%5Blat%5D=${query.lat}&aroundLatLng%5Blng%5D=${query.lng}&configure%5BhitsPerPage%5D=40`);
    }
  });

  autocomplete.on('clear', () => {
    refine(defaultRefinement);
  });
  
}

render() {
  return (
      <input
      ref={this.createRef}
      type="search"
      id="address-input"
      placeholder="Search by city or zip"
      />
  );
}
}

export default connect(HomePlaces);