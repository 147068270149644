import { Link } from "gatsby"
import React from 'react'
import HomePlaces from './home-places'

import Logo from '../../images/uhslogo-red.svg'

function LocationHeader() {
    
    return (
      <header className="sl_header">
        <div className="sl_header__search">
          <Link className="sl_logo" to="/search">
            <img alt="UHS Header Logo" src={Logo}></img>
          </Link>
          <HomePlaces
          />
        </div>{/*end sl_header__search*/}
    </header>
    )
};

export default LocationHeader