import React from "react"
import { RefinementList } from 'react-instantsearch-dom';
import { orderBy } from 'lodash'
import CustomCurrentRefinements from "../currentrefinements"

const FilterSideBar = () => {
    return (
        <>
            <CustomCurrentRefinements />
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Levels of Care</p>
                <RefinementList 
                    attribute="services.levelCare.name"
                    transformItems={items => orderBy(items, "label", "asc")}
                    showMore
                />
            </div>
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Ages Treated</p>
                <RefinementList 
                    attribute="services.age.ageGroup"
                    transformItems={items => 
                        orderBy(
                            items.map(item => ({
                                ...item,
                                label: item.label === "child" ? "Children (2-12)" : 
                                    item.label === "adolescent" ? "Adolescents (13-17)" : 
                                    item.label === "adult" ? "Adults (18-65)" : 
                                    item.label === "senior" ? "Seniors (65+)" : ''
                            })), "value", "asc"
                        )
                    }
                />
            </div>
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Disorders Treated</p>
                <RefinementList
                attribute="services.diagnosis.name" 
                transformItems={items => orderBy(items, "label", "asc")}
                translations={{
                    showMore(expanded) {
                      return expanded ? 'See Less' : 'See All Categories';
                    }
                }}
                />
            </div>
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Specialty Programs</p>
                <RefinementList 
                showMore
                limit={15}
                showMoreLimit={100}
                attribute="specialty" 
                transformItems={items => orderBy(items, "label", "asc")}
                translations={{
                    showMore(expanded) {
                      return expanded ? 'See Less' : 'See All Categories';
                    }
                }}
                />
            </div>
            <div className="sl_filter__callout">
                <p>Visit our website to see a map of all UHS locations including Acute Care hospitals, Freestanding Emergency Departments, Behavioral Health Facilities, Ambulatory Surgery Centers and Physician Networks.</p>
                <a className="sl_button sl_button--border sl_button--external" href="https://www.uhsinc.com/our-communities/" target="_blank" rel="noreferrer">View All Locations</a>
            </div>
        </>
    );    
};
export default FilterSideBar