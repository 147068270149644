import React from "react"
import {
    CustomMarker
  } from 'react-instantsearch-dom-maps';
  
const CustomNewMarker = ({hit, google}) => {
    const { address, locationName, slug } = hit
    const state = address.state.toUpperCase();

    const lineTwo = !address.line2 === null ? address.line2 : " ";
    const newSlug = slug !== null ? slug.current : null;

    const contentString =
    `<div class="sl_map__popup">
      <h3 class="sl_card__name">${locationName}</h3>
      <p class="sl_card__address">${address.line1} ${lineTwo}<br/>
      ${address.city}, ${state} ${address.code}</p>
      <a class="sl_button sl_button--simple" href="/${newSlug}">Learn More</a>
    </div>`

    const onClickMarker = ({ hit, marker }) => {
      const infoWindow = new google.maps.InfoWindow();

        if (infoWindow.getMap()) {
          infoWindow.close();
        }

        infoWindow.setContent(contentString);
        infoWindow.open(marker.getMap(), marker);
    };

    return (
        <CustomMarker
            key={hit.objectID}
            hit={hit}
            className="sl_map__marker"
            anchor={{ x: 10, y: 0 }} 
            onClick={({ marker }) => { 
            onClickMarker({
                hit,
                marker,
            });
            }}
        >
            <i className="fas fa-map-marker-alt"></i>
        </CustomMarker>
    );
}; 

export default CustomNewMarker