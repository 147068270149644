import React from 'react'

const HoursOfOperation = ({rangeObj, allDay}) => {

    if ((rangeObj === null && allDay === false) || null) {
      return (
        <>
        <small>Pending content…</small>
        </>
      );
    } else {

      if (allDay === true) {
        return (
          <>
            <p><strong>Hours of Operation: </strong>
            <span>Open 24 Hours, 7 Days a Week</span>
            </p>
          </>
        );
      } else {
        if (rangeObj) {
          const {fridayClose, fridayOpen, mondayClose, mondayOpen, tuesdayClose, tuesdayOpen, wednesdayClose, wednesdayOpen, thursdayClose, thursdayOpen, saturdayClose, saturdayOpen, sundayClose, sundayOpen} = rangeObj;
        return (
          <>
            <p><strong>Hours of Operation: </strong><br></br>
                Monday Hours: {mondayOpen.hour}:{mondayOpen.minute} to {mondayClose.hour}:{mondayClose.minute}<br></br>
                Tuesday Hours: {tuesdayOpen.hour}:{tuesdayOpen.minute} to {tuesdayClose.hour}:{tuesdayClose.minute}<br></br>
                Wednesday Hours: {wednesdayOpen.hour}:{wednesdayOpen.minute} to {wednesdayClose.hour}:{wednesdayClose.minute}<br></br>
                Thursday Hours: {thursdayOpen.hour}:{thursdayOpen.minute} to {thursdayClose.hour}:{thursdayClose.minute}<br></br>
                Friday Hours: {fridayOpen.hour}:{fridayOpen.minute} to {fridayClose.hour}:{fridayClose.minute}<br></br>
                Saturday Hours: {saturdayOpen.hour}:{saturdayOpen.minute} to {saturdayClose.hour}:{saturdayClose.minute}<br></br>
                Sunday Hours: {sundayOpen.hour}:{sundayOpen.minute} to {sundayClose.hour}:{sundayClose.minute}<br></br>
            </p>
          </>
        );
      } else {
        return (
          null
        );
      }
      }
    }
};
export default HoursOfOperation;