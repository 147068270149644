import React from "react"
import { Link } from "gatsby"

import Logo from '../images/uhslogo.svg'
import ChildrenPhoto from '../images/home/children-photo.jpg'
import ResidentialPhoto from '../images/home/residential-photo.jpg'
import OutpatientPhoto from '../images/home/outpatient-photo.jpg'
import HomePlaces from '../components/base/home-places'
import Layout from "../components/base/layout"
import SEO from "../components/base/seo"

function componentDidMount() {
  if (typeof window !== undefined) {
    let query = window.location.search;
    window.location.replace(`/search/${query}`);
  }
}

const IndexPage = (props) => {

  return (
  <Layout location={props.location}>
    <SEO title="Home" />
    <div className="sl_content sl_content--home">

      {/* Intro Search Module */}
      <div className="sl_module sl_module--search">
        <div className="sl_inner">
          <Link className="sl_logo" to="/search">
            <img alt="UHS Header Logo" src={Logo}></img>
          </Link>
          <h1>Hope. Help. <span>Healing.</span></h1>
          <p>Find a UHS behavioral health location near you.</p>
        <HomePlaces
          className="sl_module--search__box"
        />
        </div>{/*end sl_inner*/}
      </div>{/*end sl_module--search*/}

      <div className="sl_module sl_module--filterdeck sl_module--withbottom">
      <div className="sl_inner">
        <div className="sl_row small-up-1 medium-up-1 large-up-3">

          <div className="sl_cell">
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.age.ageGroup%5D%5B0%5D=child&refinementList%5Bservices.age.ageGroup%5D%5B1%5D=adolescent&page=1&configure%5BhitsPerPage%5D=40">
              <div className="sl_card__image" style={{backgroundImage: `url(${ChildrenPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Children and Adolescent</h3>
                <p>Looking for a specific age demographic?</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          <div className="sl_cell">
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.levelCare.name%5D%5B0%5D=Residential&page=1&configure%5BhitsPerPage%5D=40&configure%5BaroundRadius%5D=all">
              <div className="sl_card__image" style={{backgroundImage: `url(${ResidentialPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Residential Treatment</h3>
                <p>Find facilities that offer inpatient stay</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          <div className="sl_cell">
            <a className="sl_card sl_card--filterdeck" href="/search/?refinementList%5Bservices.levelCare.name%5D%5B0%5D=Outpatient&page=1&configure%5BhitsPerPage%5D=40&configure%5BaroundRadius%5D=all">
              <div className="sl_card__image" style={{backgroundImage: `url(${OutpatientPhoto})`}}></div>
              <div className="sl_card__content">
                <h3>Outpatient Treatment</h3>
                <p>Need to maintain a flexible schedule?</p>
              </div>
            </a>
          </div>{/*end sl_cell */}

          </div>{/*end sl_row */}
        </div>{/*end sl_inner */}
      </div>{/*end sl_module--filterdeck */}

      <div className="sl_module sl_module--callout">
        <div className="sl_inner">
          <div className="sl_card sl_card--callout">
            <h2>Looking for all of our UHS locations?</h2>
            <p>Visit our website to see a map of all UHS locations including Acute Care hospitals, Freestanding Emergency Departments, Behavioral Health Facilities, Ambulatory Surgery Centers and Physician Networks.</p>
            <a className="sl_button sl_button--border sl_button--external" href="https://www.uhsinc.com/our-communities/" target="_blank" rel="noreferrer">Visit Website</a>
          </div>{/*end sl_card */}
        </div>{/*end sl_inner */}
      </div>{/*end sl_module--callout */}
    </div>{/*end sl_content */}

    <div className="sl_content__footer sl_footer">
        <div className="sl_inner">
          <p>UHS is a registered trademark of UHS of Delaware, Inc., the management company for Universal Health Services, Inc. and a wholly-owned subsidiary of Universal Health Services. Universal Health Services, Inc. is a holding company and operates through its subsidiaries including its management company, UHS of Delaware, Inc. All healthcare and management operations are conducted by subsidiaries of Universal Health Services, Inc.</p>
          <p>Your individual health status and any required medical treatments can only be properly addressed by a professional healthcare provider. Physicians are on the medical staff of these facilities, but, with limited exceptions, are independent practitioners who are not employees or agents of these facilities. The facilities shall not be liable for actions or treatments provided by physicians. Model representations of real patients are shown. Actual patients cannot be divulged due to HIPAA regulations. For language assistance, disability accommodations and the non-discrimination notice, visit their websites.</p>
        </div>{/*end sl_inner */}
      </div>{/*end sl_footer */}
  </Layout>
  )
}

export default IndexPage
