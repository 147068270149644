import { Link } from "gatsby"
import React from 'react'
import Places from './places';
import { Configure } from 'react-instantsearch-dom';

import Logo from '../../images/uhslogo-red.svg'

const parameters = {};

function Header() {
    
    return (
      <header className="sl_header">
        <Configure {...parameters} />
        <div className="sl_header__search">
          <Link className="sl_logo" to="/search">
            <img alt="UHS Header Logo" src={Logo}></img>
          </Link>
          <Places
          />
        </div>{/*end sl_header__search*/}
    </header>
    )
};

export default Header