import React from 'react'

import GoogleMapReact from 'google-map-react'


const MapAddressComponent = (props) => {

    const LocationPin = ({ text }) => (
        <div className="sl_map__marker sl_map__marker--large">
          <i className="fas fa-map-marker-alt"></i>
        </div>
      )
    const geopoint = props.props.geopoint;
    const state = props.props.addr.state.toUpperCase();
    
    if (!geopoint) {
      return null;
    }

    return (
      <>
        <div style={{ height: '230px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                key: 'AIzaSyA1EFkKIdNERBa3ud5_SjESfBnNcV4hXLU',
                }}
                defaultCenter={{ lat: props.props.geopoint.lat, lng: props.props.geopoint.lng}}
                defaultZoom={11}
            >
            <LocationPin
                lat={props.props.geopoint.lat}
                lng={props.props.geopoint.lng}
                text={props.props.name}
            />
            </GoogleMapReact>
        </div>
        <div className="sl_sidebar__map__content">
        <p>{props.props.addr.line1} {props.props.addr.line2}<br/>{props.props.addr.city}, {state} {props.props.addr.code}</p>
        <a className="sl_button sl_button--secondary sl_button--directions" href={`https://www.google.com/maps/place/${props.props.addr.line1},+${props.props.addr.city},+${props.props.addr.state}+${props.props.addr.code}/@${props.props.geopoint.lat},${props.props.geopoint.lng},17z/`}>Get Directions</a>
        </div>
      </>
    );
};

export default MapAddressComponent;