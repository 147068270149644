import React from "react"
import { connectInfiniteHits } from 'react-instantsearch-dom';

import CustomStats from "./customstats"
import CustomQueryRefinement from "./filters/queryrefinement"

const Hit = ({ hit }) => {

  const {gallery, address, slug, locationName, telNum, logoH} = hit
  const number = telNum !== undefined ? telNum.main : null;
  const state = address !== undefined ? address.state.toUpperCase() : null;
  const line1 = address !== undefined ? address.line1 : null;
  const line2 = address !== undefined ? address.line2 : null;
  const city = address !== undefined ? address.city : null;
  const code = address !== undefined ? address.code : null;
  const newSlug = slug !== undefined ? slug.current : null;
  const image = getImageValue();
  const logo = getLogoValue()

  function getImageValue() {
    if (gallery !== undefined) {
      if (gallery.length !== 0) {
        if(gallery[0].asset !== undefined) {
          return gallery[0].asset.url;
        }
      }
    } else {
      return null;
    }
  }

  function getLogoValue() {
    if (logoH !== undefined) {
      if(logoH.asset !== undefined ) {
        return logoH.asset.url
      }
    } else {
      return null;
    }
  }

  return (
    <div className="sl_card sl_card--results">
      <div className="sl_row">
        
        { image !== undefined && image !== null
        ?
        <a className="sl_cell sl_card__image" href={`/${newSlug}`} style={{ backgroundImage: `url(${image})` }}>
        </a>
        : logo !== undefined && logo !== null ?
        <a className="sl_cell sl_card__logo" href={`/${newSlug}`}>
        <img src={logo} alt="Facility Logo"></img>
        </a>
        :
        <a className="sl_cell sl_card__image" href={`/${newSlug}`}>
        </a>
        }
        
        <div className="sl_cell sl_card__content xlarge-auto large-12 medium-auto small-12">
          <h3 className="sl_card__name">{locationName}</h3>
          <p className="sl_card__address">{line1} {line2}<br/>
          {city}, {state} {code}</p>
          <div className="sl_button-group">
            <a className="sl_button sl_button--phone" href={`tel:${number}`}>{number}</a>
            <a className="sl_button sl_button--simple" href={`/${newSlug}`}>Learn More</a> 
          </div>
        </div>
      </div>
    </div>
  );
}

const Results = ({ 
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
}) => (
      <>
      <CustomQueryRefinement clearsQuery/>
      <CustomStats hits={hits} />
      <div className="ais-InfiniteHits">
        <ul className="ais-InfiniteHits-list">
        {hits.map(hit => (
        <li key={hit.objectID} className="ais-InfiniteHits-item">
          <Hit hit={hit}/>
        </li>
        ))}
        </ul>
        <div className="sl_results__load">
          <div className="sl_button" disabled={!hasMore} onClick={refineNext}>
            Load More
          </div>
        </div>
      </div>
      </>
);

export default connectInfiniteHits(Results);